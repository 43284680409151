$(window)
  .on('turbolinks:load', function () {
    if (!window.appName) window.appName = {};
    window.appName.formDirty = false;
    window.appName.formSubmit = false;
    window.appName.dirtyMessage = '保存していない内容が失われますが、よろしいですか';

    $(':input:not(.no-alert)').change(function () {
      if (!window.appName.formDirty) window.appName.formDirty = true;
    });
    $('form').submit(function () {
      window.appName.formSubmit = true;
    });
  })
  // Turbolinksでページが切り替わるタイミング
  .on("page:before-change turbolinks:before-visit", function () {
    if (window.appName.formDirty) {
      return confirm(window.appName.dirtyMessage);
    }
  })
  // リロードされたり，タブやウインドウが閉じられる前のタイミング
  .bind("beforeunload", function (event) {
    if (!window.appName.formSubmit && window.appName.formDirty) {
      event.returnValue = window.appName.dirtyMessage;
      return event.returnValue;
    }
  });
