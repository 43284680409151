$(document).on('cocoon:after-insert', function (e, insertedItem, noOpen) {
  if (insertedItem.find('select').length === 0) {
    return;
  }

  _apply($(insertedItem));
  if (noOpen) {
    return
  }
  var event = new MouseEvent('mousedown', { button: 0 });
  insertedItem.find('.select2-selection')[0].dispatchEvent(event);
});

$(document).on('turbolinks:load', function () {
  _apply($(document))
});

$(document).on('turbolinks:before-cache', function () {
  $('.select2.form-control').select2('destroy');
});

var _apply = function (dom) {
  var getQueryParameter = function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  var params = {
    dataType: 'json',
    delay: 100,
    processResults: function (data) {
      return {
        results: data.map(r => ({ id: r.id, text: r.name }))
      };
    }
  };

  dom.find('.select-service').select2({
    theme: "bootstrap",
    placeholder: '選択',
    ajax: Object.assign({}, params, {
      url: '/services',
      processResults: function (data) {
        return {
          results: data.map(r => ({ id: r.id, text: r.name }))
        };
      },
    })
  });

  const userOptions = {
    theme: "bootstrap",
    placeholder: '選択',
    dropdownAutoWidth: true,
    width: 'auto',
    allowClear: true,
    ajax: Object.assign({}, params, {
      url: '/admin/users.json',
      processResults: function (data) {
        return {
          results: data.map(r => ({ id: r.id, text: r.name_with_id }))
        }
      }
    })
  }
  dom.find('.select-user').select2(userOptions);

  dom.find('.select-service-category').select2({
    theme: "bootstrap",
    placeholder: '選択',
    ajax: Object.assign({ url: '/service_categories' }, params)
  });


  const innerForms = $('.inner-form');
  const texts = $('.texts');
  $('.inner-form-container').on('click', function (event) {
    const className = 'container-show'
    const target = $(this);
    const prev = $('.' + className);
    if (target.hasClass(className)) {
      return;
    }
    target.addClass(className);
    prev.removeClass(className)
    prev.find('.inner-form').hide();
    prev.find('.text').show();
    target.find('.inner-form').show();
    target.find('.text').hide();
    const options = Object.assign({}, userOptions, {
      width: '140px'
    })
    target.find('.select2').select2(options);
  })

}

