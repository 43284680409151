$(document).on('turbolinks:load', function () {
  $(document).on("ajax:error", ".comment-form", function (e) {
    $(e.target).find('.comment-error').html(e.detail[0].error);
  });
  $('.comment-edit-link').on('click', function (e) {
      const wrapper = $(e.target).closest('.history-area');
      wrapper.find('.history-content').toggle();
      wrapper.find('.history-form').toggle();
    }
  )
  $('.comment-show-button').on('click', function(e){
    $('#comment-input-area').slideToggle()
  });
});
