// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'

import '@fortawesome/fontawesome-free/js/all'
import 'flag-icon-css/css/flag-icon.css'
import '../css/application.scss'
require("trix")
require("@rails/actiontext")

require("../src/direct_uploads")
require("../src/dirty_form")
require("../src/direct_uploads")
require("../src/selectbox")
require("../src/comments")
require('../src/registration')
